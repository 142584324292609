.mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    width: 100vw;
    height: 100vh;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 5rem;

    width: 55vw;
    height: 60vh;
}

.textContainer {
    height: 20vh;
}

.formContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50vw;
    column-gap: 2rem;
    height: 10vh;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50vw;
}

.imgContainer1 {
    display: flex;
    background-image: url('../assets/images/pianob-img2.jpg');
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */

    width: 40vw;
    height: 100vh;
}

.imgContainer2 {
    display: flex;
    background-image: url('../assets/images/pianob-img1.jpg');
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    transform: rotate(180deg);

    width: 40vw;
    height: 100vh;
}

.imgContainer3 {
    display: flex;
    background-image: url('../assets/images/pianob-img3.jpg');
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */

    width: 40vw;
    height: 100vh;
}

.logo {
    margin-bottom: 50px;
    width: 150px;
}

.prefix {
    width: 5vw;
    margin-right: 5px !important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@media only screen and (max-width: 600px) {
    .infoContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;

        padding: 2rem;

        width: 80vw;
        height: 100vh;
    }
    .imgContainer1 {
        width: 0;
    }
    .imgContainer2 {
        width: 0;
    }
    .imgContainer3 {
        width: 0;
    }
    .formContainer {
        flex-direction: column;
        row-gap: 1rem;
        height: 30vh;
        width: 70vw;
    }
    .checkboxContainer {
        width: 90vw;
    }
    .logo {
        margin-bottom: 10px;
    }

    .prefix {
        width: 20vw;
        margin-right: 5px;
    }
}

